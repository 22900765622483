<template>
    <iq-card class="d-flex justify-content-between gap_2 align-items-center p-3 filter flex-lg-row flex-column">
      <slot></slot>
      <div class="d-none d-lg-block" v-if="multiLayouts">
        <div class="d-flex justify-end gap_1">
        <span  @click="$emit('changeLayout','vertical'); selectedItem=1">
          <svg id="Group_2912" data-name="Group 2912" :class="{'active':selectedItem==1}" xmlns="http://www.w3.org/2000/svg" width="39.519" height="37.754" viewBox="0 0 39.519 37.754">
            <path id="_546da5c87624a3107683cccc7f51e061" data-name="546da5c87624a3107683cccc7f51e061" d="M21.739,13.4H17.9a3.748,3.748,0,0,0-3.836,3.654V20.7A3.748,3.748,0,0,0,17.9,24.357h3.836A3.748,3.748,0,0,0,25.575,20.7V17.05A3.748,3.748,0,0,0,21.739,13.4m-14.066,0H3.836A3.748,3.748,0,0,0,0,17.05V20.7a3.748,3.748,0,0,0,3.836,3.654H7.672A3.748,3.748,0,0,0,11.509,20.7V17.05A3.748,3.748,0,0,0,7.672,13.4M21.739,0H17.9a3.748,3.748,0,0,0-3.836,3.654V7.307A3.748,3.748,0,0,0,17.9,10.961h3.836a3.748,3.748,0,0,0,3.836-3.654V3.654A3.748,3.748,0,0,0,21.739,0M11.509,3.654V7.307a3.748,3.748,0,0,1-3.836,3.654H3.836A3.748,3.748,0,0,1,0,7.307V3.654A3.748,3.748,0,0,1,3.836,0H7.672a3.748,3.748,0,0,1,3.836,3.654" transform="translate(0 0)" fill="#3f6854" fill-rule="evenodd"/>
            <path id="_546da5c87624a3107683cccc7f51e061-2" data-name="546da5c87624a3107683cccc7f51e061" d="M21.739,0H17.9a3.748,3.748,0,0,0-3.836,3.654V7.307A3.748,3.748,0,0,0,17.9,10.961h3.836a3.748,3.748,0,0,0,3.836-3.654V3.654A3.748,3.748,0,0,0,21.739,0M11.509,3.654V7.307a3.748,3.748,0,0,1-3.836,3.654H3.836A3.748,3.748,0,0,1,0,7.307V3.654A3.748,3.748,0,0,1,3.836,0H7.672a3.748,3.748,0,0,1,3.836,3.654" transform="translate(0 26.793)" fill="#3f6854" fill-rule="evenodd"/>
            <path id="_546da5c87624a3107683cccc7f51e061-3" data-name="546da5c87624a3107683cccc7f51e061" d="M11.509,3.654V7.307a3.748,3.748,0,0,1-3.836,3.654H3.836A3.748,3.748,0,0,1,0,7.307V3.654A3.748,3.748,0,0,1,3.836,0H7.672a3.748,3.748,0,0,1,3.836,3.654" transform="translate(28.01 26.793)" fill="#3f6854" fill-rule="evenodd"/>
            <path id="_546da5c87624a3107683cccc7f51e061-4" data-name="546da5c87624a3107683cccc7f51e061" d="M11.509,3.654V7.307a3.748,3.748,0,0,1-3.836,3.654H3.836A3.748,3.748,0,0,1,0,7.307V3.654A3.748,3.748,0,0,1,3.836,0H7.672a3.748,3.748,0,0,1,3.836,3.654" transform="translate(28.01 13.397)" fill="#3f6854" fill-rule="evenodd"/>
            <path id="_546da5c87624a3107683cccc7f51e061-5" data-name="546da5c87624a3107683cccc7f51e061" d="M11.509,3.654V7.307a3.748,3.748,0,0,1-3.836,3.654H3.836A3.748,3.748,0,0,1,0,7.307V3.654A3.748,3.748,0,0,1,3.836,0H7.672a3.748,3.748,0,0,1,3.836,3.654" transform="translate(28.01 0)" fill="#3f6854" fill-rule="evenodd"/>
          </svg>
        </span>
          <span @click="$emit('changeLayout','horizontal'); selectedItem=2">
          <svg xmlns="http://www.w3.org/2000/svg" width="39.446" height="36.373"   :class="['bar', {'active':selectedItem==2}]" viewBox="0 0 39.446 36.373">
            <path id="XMLID_101_" d="M43.616,17.16H10.83a3.33,3.33,0,0,1,0-6.66H43.616a3.33,3.33,0,0,1,0,6.66Zm3.33,11.527a3.323,3.323,0,0,0-3.33-3.33H10.83a3.33,3.33,0,0,0,0,6.66H43.616A3.323,3.323,0,0,0,46.946,28.686Zm0,14.856a3.323,3.323,0,0,0-3.33-3.33H10.83a3.33,3.33,0,0,0,0,6.66H43.616A3.323,3.323,0,0,0,46.946,43.543Z" transform="translate(-7.5 -10.5)" fill="#3f6854"/>
          </svg>
        </span>
        </div>
      </div>
    </iq-card>
</template>

<script>
// import { core } from '@/config/pluginInit'

export default {
  props: {
    multiLayouts: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedItem: 2
    }
  },
  methods: {
    changeWidth () {
      if (window.innerWidth <= 992) {
        this.$emit('changeLayout', 'vertical')
        this.selectedItem = 1
      }
    }
  },
  mounted () {
    // core.index()
    this.$emit('changeLayout', 'horizontal')
    window.addEventListener('resize', this.changeWidth)
  }
}
</script>

<style lang="scss">
.filter {
  .active path {
    fill: var(--iq-primary) !important;
  }
  svg {
    width: 28px;
    cursor: pointer;
    path {
      fill: #9B9B9B;
    }
  }
}
</style>
