import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
    }
  },
  methods: {
    check (payload) {
      return this.$store.getters['cart/checkIfProductInCart'](payload)
    },
    addToCart (product) {
      const productCart = {
        store_info: { id: product.store_id },
        store_products: [{ id: product.id, quantityInCart: 1 }]
      }
      if (localStorage.getItem('userToken')) {
        marketPlace.postProductInCart({ carts: [productCart] }).then(res => {
          core.showSnackbar('success', 'تم اضافة المنتج فى السلة')
          this.$store.dispatch('cart/addToCart', product)
        })
          .catch(err => {
            console.log((err))
          })
      } else {
        this.$store.dispatch('cart/addToCart', product)
        core.showSnackbar('success', 'تم اضافة المنتج فى السلة')
      }
    },
    addToFavourite (product) {
      if (this.checkIfLogin()) {
        this.$store.dispatch('cart/addToFavourite', product)
        marketPlace.addProductWishlist({ product_id: product.id }).then(res => {
        })
          .catch(err => {
            console.log((err))
          })
      } else {
        core.showSnackbar('success', 'يجب تسجيل الدخول')
      }
    },
    checkFavourite (product) {
      return this.$store.getters['cart/checkProductInFavourite'](product)
    },
    deleteProductFavourite (product) {
      marketPlace.deleteProductWishList(product.id).then(res => {
        this.$store.dispatch('cart/deleteProductInFavourite', product)
      })
        .catch(err => {
          console.log((err))
        })
    }
  }
}
