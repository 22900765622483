<template>
  <div>
      <categories-swiper @changeCat="updateCat"/>
      <filter-stores class="mt-4 d-flex" @changeLayout="changeLayout">
        <div class="d-flex justify-content-between flex-grow-1 w-100">
          <h3 class="text-primary font-weight-bold ml-4"> {{$t('main.products')}} </h3>
          <main-select
              :options="sort"
              class="mb-0 min-filter"
              :placeholder="$t('main.getDefault')"
              dir="rtl"
              label="name"
              :reduce="item => item.value"
              @selecting="selectSortData"
          />
        </div>
      </filter-stores>
      <b-row>
      <b-col md="3"  v-for="(product,key) in allProducts"  :class="[{ 'col-lg-3 col-md-6 active': selectedView === 'productCard', 'col-md-12': selectedView !== 'productCard' }]" :key="key">
          <transition name="router-anim" :enter-active-class="`animated zoomIn`" mode="out-in"
                      :leave-active-class="`animated zoomOut`" >
            <component
                :productItem ="product"
                :is="selectedView"></component>
          </transition>
      </b-col>
    </b-row>
      <div v-if="loadingProducts" class="d-flex align-items-center justify-content-center mt-5">
      <b-spinner large type="grow" variant="primary"></b-spinner>
    </div>
<!--    <div class="d-flex justify-content-center align-items-center my-4" v-if="pagination.currentPage !== pagination.totalPages">-->
<!--      <b-button @click="pagination.currentPage++ ; getAllProducts()" variant="primary" class="w-50 text-white"> {{$t('marketplace.seeMore')}}</b-button>-->
<!--    </div>-->
    </div>
</template>

<script>
import categoriesSwiper from '@/modules/servicePages/marketplace/components/profile/allCategoriesSwipper'
import productCard from '@/modules/servicePages/marketplace/components/productCard'
import filterStores from '@/modules/servicePages/marketplace/components/filterMarketPlace'
import profileProductsList from '@/modules/servicePages/marketplace/components/profile/profileProductsList'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  name: 'productsCategories',
  components: { categoriesSwiper, filterStores, productCard, profileProductsList },
  data () {
    return {
      selectedView: 'productCard',
      loadingProducts: true,
      allProducts: [],
      selectSort: 'DESC',
      selectedCat: '',
      pagination: {
        currentPage: 1
      },
      sort: [
        {
          name: 'من الأحدث للأقدم',
          value: 'ASC'
        },
        {
          name: 'من الأقدم للأحدث',
          value: 'DESC'
        }
      ]
    }
  },
  // computed: {
  //   getProductsStore () {
  //     return this.$store.getters['cart/getAllProductsStores']
  //   }
  // },
  methods: {
    changeLayout (layout) {
      if (layout === 'vertical') {
        this.selectedView = 'productCard'
      } else {
        this.selectedView = 'profileProductsList'
      }
    },
    selectSortData (selectSort) {
      this.selectSort = selectSort.value
      this.getAllProducts(this.push = false)
    },
    updateCat (cat) {
      this.allProducts = []
      this.pagination.currentPage = 1
      this.selectedCat = cat
      this.getAllProducts()
    },
    getAllProducts (push = true) {
      this.loadingProducts = true
      // eslint-disable-next-line eqeqeq
      marketPlace.getAllProducts(this.$route.params.slogan, this.selectSort, this.selectedCat, this.pagination.currentPage).then(res => {
        if (push) {
          this.allProducts.push(...res.data.data)
        } else {
          this.allProducts = res.data.data
        }
        this.pagination = res.data.meta
      }).finally(() => {
        this.loadingProducts = false
      })
    }
  },
  created () {
    this.getAllProducts()
  }
}
</script>

<style>
.min-filter{
  width: 20%;
}
@media (max-width: 768px) {
  .min-filter{
    width: 100%;
  }
}
</style>
