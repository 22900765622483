import { marketPlace } from '@/axios'

export default {
  getAllStoresGategories () {
    return marketPlace().get('stores-categories')
  },
  getAllCities () {
    return marketPlace().get('cities')
  },
  getAllStores (categorySlog, country, city, order) {
    return marketPlace().get(`category/${categorySlog}/stores?country=${country}&city=${city}&order=${order}`)
  },
  getStoreInfo (id) {
    return marketPlace().get(`stores/${id}`)
  },
  getAllProducts (id, sort = 'DESC', cat = '', page = 1) {
    return marketPlace().get(`${id}/products?page=${page}&status=published&order=${sort}&category=${cat}&limit=100`)
  },
  getCategoryStore (id) {
    return marketPlace().get(`${id}/product-categories`)
  },
  getProductDetail (id) {
    return marketPlace().get(`products/${id}`)
  },
  getProductCart () {
    return marketPlace().get('carts')
  },
  getUpdatedProductsFromServer (payload) {
    return marketPlace().post('carts/without-auth', payload)
  },
  postProductInCart (payload) {
    return marketPlace().post('carts', payload)
  },
  deleteProductInCart (id) {
    return marketPlace().delete(`carts/${id}`)
  },
  addProductWishlist (payload) {
    return marketPlace().post('customer/wish-lists', payload)
  },
  getProductWishlist () {
    return marketPlace().get('customer/wish-lists')
  },
  deleteProductWishList (id) {
    return marketPlace().delete(`customer/wish-lists/${id}`)
  },
  getAddress () {
    return marketPlace().get('customer-addresses')
  },
  getSpacificAddress (id) {
    return marketPlace().get(`customer-addresses/${id}`)
  },
  addAddress (payload) {
    return marketPlace().post('customer-addresses', payload)
  },
  editAddress (id, payload) {
    return marketPlace().put(`customer-addresses/${id}`, payload)
  },
  addOrder (payload) {
    return marketPlace().post('customer/orders', payload)
  },
  addCoupon (payload) {
    return marketPlace().post('customer/activate-coupon', payload)
  },
  payWithCardInMarketPlace (payload) {
    return marketPlace().post('customer/orders/success-payment', payload)
  }
}
